<template>
  <div class="image-container">
    <img
      src="@/assets/5.jpg"
      alt="Imagen de ejemplo"
      class="cover-image"
    >
    <div class="overlay" /> <!-- Superposición de transparencia -->
  </div>
</template>

<script>
export default {
  name: 'CoverImageComponent',
};
</script>

<style scoped>
.image-container {
  width: 100vw; /* Ocupar el 100% del ancho de la ventana */
  height: 400px; /* Ajusta la altura según tus necesidades */
  overflow: hidden; /* Oculta cualquier parte de la imagen que se salga del contenedor */
  position: relative;
  margin: 0; /* Elimina márgenes para evitar desplazamiento */
}

.cover-image {
  width: 100%; /* La imagen ocupará el 100% del ancho del contenedor */
  height: 100%; /* La imagen abarcará el 100% de la altura del contenedor */
  object-fit: cover; /* Ajuste tipo cover para que la imagen se ajuste proporcionalmente */
  position: absolute; /* Fija la imagen dentro del contenedor */
  top: 0;
  left: 0;
}

.overlay {
  position: absolute; /* Posiciona la superposición dentro del contenedor */
  top: 0;
  left: 0;
  width: 100%; /* Ocupar el 100% del ancho */
  height: 100%; /* Ocupar el 100% de la altura */
  background: linear-gradient(to bottom, rgba(66, 64, 64, 0.4) 30%, rgba(35, 35, 34, 0.8));
  /* background-color: rgba(255, 182, 193, 0.5); */ /* Alternativa: rosa claro */
}
</style>
