<template>
  <div>
    <!-- Contenedor Bienvenida Padres -->
    <div class="padres-container">
      <img
        src="@/assets/lineas01.svg"
        alt="Decoración SVG"
        class="decorative-svg"
      >

      <div
        ref="texto1Ref"
        class="texto-container text"
      >
        Mis Padres: <br>
      </div>

      <div
        ref="texto0Ref"
        class="texto-container texto-novia"
      >
        <span class="novia"> <br>
          Lupita Rubio Leyva </span>
        <br><span class="starlove" />
        Jesús Perea Acosta
      </div>
    </div>

    <!-- Texto 1 -->

    <!-- Texto 2 -->
    <div class="texto-container3">
      <div
        ref="texto2Ref"
        class="titulo-novia"
      >
        <br>Mis Padrinos: <br>
      </div>
      <br>
      <!-- Contenedor principal en columnas -->
      <div class="columnas-container">
        <!-- Primera columna -->
        <div class="columna">
          <span class="nombre-novio"><br><span class="titulo-padrino">Madrina:</span> <br>Nohely Rubio Leyva</span>
          <span class="nombre-novio"><br><span class="titulo-padrino">Padrino:</span> <br>Carlos Genaro Perea López</span>
        </div>

        <!-- Segunda columna -->
        <div class="columna">
          <span class="nombre-novio"><br><span class="titulo-padrino">Madrina:</span> <br>Nadia Herrera Castro</span>
          <span class="nombre-novio"><br><span class="titulo-padrino">Padrino:</span> <br>Carlos Alberto Perea Acosta</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css';

export default {
  name: 'BienvenidaPadresPage',
  setup() {
    const texto1Ref = ref(null);
    const texto2Ref = ref(null);
    const texto3Ref = ref(null);
    const texto0Ref = ref(null);

    const observeElement = (element) => {
      if (element.value) {
        const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: [0.1],
        };

        const observerCallback = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              element.value.classList.add('animate__animated', 'animate__fadeInUp');
              element.value.style.opacity = '1';
              element.value.style.transform = 'translateY(0)';

              element.value.addEventListener('animationend', () => {
                element.value.classList.remove('animate__animated', 'animate__fadeInUp');
              }, { once: true });
            } else {
              element.value.style.opacity = '0';
              element.value.style.transform = 'translateY(50px)';
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element.value);
      }
    };

    onMounted(async () => {
      await nextTick();
      observeElement(texto0Ref);
      observeElement(texto1Ref);
      observeElement(texto2Ref);
      observeElement(texto3Ref);
    });

    return { texto0Ref, texto1Ref, texto2Ref, texto3Ref };
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}

@font-face {
  font-family: 'Starlove';
  src: url('../assets/fonts/starlove.ttf') format('truetype'); /* Asegúrate de usar la extensión correcta */
  font-weight: normal;
  font-style: normal;
}

/* Contenedor Bienvenida Padres */
.padres-container {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: rgba(189, 181, 189, 0.484);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
}

/* Estilo para los textos */
.texto-container {
  padding: 5px;
  margin: 10px 0;
  opacity: 0;
  font-size: 20px;
  font-family: 'Dancing Script', sans-serif;
  font-weight: 350;
  color: #333;
  border-radius: 10px;
  z-index: 2;
  width: 90%;
  transform: translateY(50px);
}

.novia {
  font-size: 20px;
  font-family: 'Dancing Script', sans-serif;
  font-weight: 350;
  color: #333;
}

.texto-container.animate__animated {
  transform: translateY(0);
  opacity: 1;
}

.nombre-icono-container {
  display: flex;
  align-items: center;
}

.icono {
  margin-left: 8px; /* Ajusta el espacio entre el texto y el ícono */
  color: #7e667b; /* Cambia el color según tu preferencia */
  font-size: 24px; /* Ajusta el tamaño del ícono */
}

/* Estilo para la palabra Novia */
.titulo-novia {
  font-size: 26px;
  font-family: 'FeelingPassionate';
  font-weight: 350;
  color: #917087d4;
}

/* Estilo para la palabra Novio */
.titulo-novio {
  font-size: 14px;
  color: #454444df; /* Cambia el color según lo desees */
  font-weight: bold; /* Negrita o estilo que prefieras */
  font-family: 'Roboto';
}

.text {
  font-size: 26px;
  font-family: 'FeelingPassionate';
  margin-top: 40px;
  font-weight: 350;
  color: #917087d4;
}

/* Estilo para los nombres de los novios */
.nombre-novia {
  font-family: 'Dancing Script', sans-serif;
  opacity: 1; /* Asegura que el texto esté visible */
  color: #755b71;
  transition: opacity 0.3s ease;
  font-size: 30px;
  line-height: 1; /* Reducir el espacio entre líneas */
}

.nombre-novio {
  font-family: 'Dancing Script', sans-serif;
  margin-top: 30hv;
  font-size: 12px;
  color: #10100fa7; /* Color para los nombres */
  font-weight: normal; /* Peso de letra normal para los nombres */
}

.starlove {
  margin-top: -30px;
  font-family: 'starlove', cursive; /* Cambia 'cursive' por la fuente por defecto si deseas */
  font-size: 44px; /* Ajusta el tamaño según lo necesites */
  color: #93806d; /* Ajusta el color según tu preferencia */
  top: -20px;
}

/* Contenedor principal para las columnas */
.columnas-container {
  display: flex; /* Activar flexbox */
  justify-content: center; /* Centrar las columnas horizontalmente */
  align-items: center; /* Asegurar que las columnas se alineen verticalmente */
  flex-wrap: wrap; /* Permitir ajuste en pantallas pequeñas */
  gap: 0; /* Sin espacio entre columnas */
  margin: 0; /* Sin margen externo */
  padding: 0; /* Sin relleno interno */
  width: 100%; /* Asegurar que el contenedor ocupe todo el ancho */
  text-align: center; /* Centrar texto dentro del contenedor */
}

/* Contenedores individuales */
.texto-container3,
.texto-container2 {
  padding-bottom: 30px;
  background-color: rgba(189, 181, 189, 0.484);
  flex: 1; /* Cada columna ocupa el mismo espacio */
  max-width: 100%; /* Limitar el ancho al 50% del contenedor principal */
  text-align: center; /* Centrar texto dentro de cada contenedor */
  box-sizing: border-box; /* Incluir bordes y relleno en el ancho/altura */
}

/* Estilo para las columnas individuales */
.columna {
  margin-bottom: 20hv;
  display: flex; /* Activar flexbox para elementos internos */
  flex-direction: column; /* Alinear contenido verticalmente */
  justify-content: center; /* Centrar elementos verticalmente */
  align-items: center; /* Centrar elementos horizontalmente */
  flex: 1; /* Permitir que cada columna ocupe el mismo espacio */
  max-width: 100%; /* Cada columna no excederá el ancho del contenedor principal */
  text-align: center; /* Centrar texto dentro de cada columna */
  padding: 0; /* Sin relleno interno */
  gap: 0; /* Sin espacio adicional entre elementos */
}

.titulo-padrino {
  font-size: 12px;
  color: #917087d4;
  font-family: 'Roboto';
  text-transform: capitalize;
  font-weight: 500;
}
.decorative-svg {
  display: block; /* Elimina el espacio en línea generado por el SVG */
  margin: 0; /* Sin margen */
  padding: 0; /* Sin relleno */
  line-height: 0; /* Evita espacio causado por la altura de línea */
  width: 100%; /* Ajusta el ancho según sea necesario */
  height: auto; /* Mantén las proporciones */
}
</style>
