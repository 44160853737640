<template>
  <swiper
    :effect="'coverflow'"
    :grab-cursor="true"
    :centered-slides="true"
    :slides-per-view="'auto'"
    :coverflow-effect="{
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true
    }"
    :pagination="true"
    :modules="modules"
    :autoplay="{ delay: 1000 }" 
    class="mySwiper"
  >
    <swiper-slide>
      <img 
        :src="require('@/assets/1.jpg')" 
        alt="Nature 1"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/2.jpg')" 
        alt="Nature 2"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/3.jpg')" 
        alt="Nature 3"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/4.jpg')" 
        alt="Nature 4"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/5.jpg')" 
        alt="Nature 5"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/6.jpg')" 
        alt="Nature 6"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/1nn.jpg')" 
        alt="Nature 7"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/2nn.jpg')" 
        alt="Nature 8"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/3nn.jpg')" 
        alt="Nature 9"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/4nn.jpg')" 
        alt="Nature 10"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/5nn.jpg')" 
        alt="Nature 11"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/6nn.jpg')" 
        alt="Nature 12"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/7nn.jpg')" 
        alt="Nature 13"
        @click="toggleEnlarge"
      >
    </swiper-slide>
    <swiper-slide>
      <img 
        :src="require('@/assets/8nn.jpg')" 
        alt="Nature 14"
        @click="toggleEnlarge"
      >
    </swiper-slide>

   
    <!-- Agrega más diapositivas según sea necesario -->
  </swiper>
</template>

<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/effect-coverflow';
  import 'swiper/css/pagination';

  // Import required modules from Swiper 10
  import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules'; // Añade Autoplay

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const toggleEnlarge = (event) => {
        const img = event.target;
        img.classList.toggle('enlarged');
      };

      return {
        modules: [EffectCoverflow, Pagination, Autoplay], // Añade Autoplay a los módulos
        toggleEnlarge,
      };
    },
  };
</script>

<style scoped>
.mySwiper {
  width: 80%;
  height: auto;
  background-color: rgba(189, 181, 189, 0.484);
  background-size: cover; /* Ajusta el tamaño de la imagen de fondo */
}

.swiper-slide {
  position: relative; /* Necesario para posicionar el pseudo-elemento del reflejo */
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  display: block;
  -webkit-box-reflect: below 1px linear-gradient(transparent, transparent , #0002 , #0004);
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative;
  z-index: 10; /* Asegura que la imagen esté sobre el reflejo */
}

/* Añade el reflejo */
.swiper-slide::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%; /* Ajusta la altura del reflejo según sea necesario */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  opacity: 0.5; /* Ajusta la opacidad del reflejo según sea necesario */
  transform: scaleY(-1);
  z-index: 0;
  pointer-events: none; /* Asegura que el reflejo no interfiera con las interacciones */
}

/* Efecto de ampliación de imagen con reflejo */
.swiper-slide img.enlarged {
  transform: scale(1.5); /* Ajusta el factor de escala según sea necesario */
  z-index: 10; /* Asegúrate de que la imagen ampliada esté sobre las otras diapositivas */
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>
