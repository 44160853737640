<template>
  <div id="app">
    <div class="preload portada-1" />
    <div class="preload portada-2" />
    <div class="preload portada-3" />

    <div class="preload gif-loader" />
    <InicioLogo v-if="isSmallScreen" />
    <Componentep
      v-if="isSmallScreen"
      :id-invitado="idInvitado"
    />
    <pantalla-g v-else /> <!-- Aquí se muestra el componente pantalla_g.vue si no es pequeña -->
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import InicioLogo from './components/inicio.vue';
import Componentep from './components/contenedor.vue';
import PantallaG from './components/pantallas_g.vue'; // Asegúrate de importar el componente

export default {
  name: 'App',
  components: {
    InicioLogo,
    Componentep,
    PantallaG,
  },
  setup() {
    const route = useRoute();
    const idInvitado = ref(Number(route.params.idInvitado) || undefined);
    const idFestejado = ref(Number(route.params.idFestejado) || undefined);
    const isSmallScreen = ref(window.innerWidth < 768); // Determina si la pantalla es pequeña

    const isValidIdInvitado = computed(() => !isNaN(idInvitado.value) && idInvitado.value !== undefined);
    const isValidFestejado = computed(() => !isNaN(idFestejado.value) && idFestejado.value !== undefined);

    // Watchers para actualizar los IDs
    watch(() => route.params.idInvitado, (newId) => {
      idInvitado.value = Number(newId);
    });

    watch(() => route.params.idFestejado, (newId) => {
      idFestejado.value = Number(newId);
    });

    // Listener para detectar cambios en el tamaño de la pantalla
    window.addEventListener('resize', () => {
      isSmallScreen.value = window.innerWidth < 768;
    });

    return {
      idInvitado,
      idFestejado,
      isSmallScreen,
      isValidIdInvitado,
      isValidFestejado,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.preload {
  visibility: hidden;
  background-size: cover;
}

.preload.portada-1 {
  background-image: url('./assets/portada-1.jpg');
}

.preload.portada-2 {
  background-image: url('./assets/inicio.png');
}

.preload.portada-3 {
  background-image: url('./assets/1.jpg');
}


.preload.gif-loader {
  background-image: url('./assets/iniciogif.gif'); /* Cambia 'tu-gif.gif' por el nombre de tu GIF */
  width: 100%; /* Ajusta el tamaño según sea necesario */
  height: 100%; /* Ajusta el tamaño según sea necesario */
}
</style>
