<template>
  <div class="container d-flex align-items-center justify-content-center vh-100">
    <!-- Overlay con el formulario de confirmación de asistencia -->
    <div class="overlay p-5 rounded-4 shadow-lg">
      <div class="confirmation-container">
        <div class="custom-title mb-4 text-center">
          Confirmación de Asistencia
        </div>
        <div class="textninos" /> - Respetuosamente no niños.
        <form @submit.prevent="handleSubmit">
          <div class="form-group mb-3">
            <label
              for="name"
              class="form-label"
            ><br>Nombre</label>
            <input
              id="name"
              v-model="name"
              type="text"
              class="form-control"
              placeholder="Ingresa tu nombre"
              required
            >
          </div>

          <div class="form-group mb-3">
            <label
              for="phone"
              class="form-label"
            >Teléfono</label>
            <input
              id="phone"
              v-model="phone"
              type="tel"
              class="form-control"
              placeholder="Ingresa tu teléfono"
              required
            >
          </div>

           
           
          <div
            v-if="!isXv"
            class="form-group mb-3"
          >
            <label class="form-label">¿Invitado por?</label>
            <div class="d-flex justify-content-start gap-4">
              <div class="form-check">
                <input
                  id="novia"
                  v-model="invitedBy"
                  type="radio"
                  value="novia"
                  class="form-check-input"
                  required
                >
                <label
                  class="form-check-label"
                  for="novia"
                >Novia</label>
              </div>
              <div class="form-check">
                <input
                  id="novio"
                  v-model="invitedBy"
                  type="radio"
                  value="novio"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="novio"
                >Novio</label>
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label class="form-label">¿Asistirás?</label>
            <div class="d-flex justify-content-start gap-4">
              <div class="form-check">
                <input
                  id="si"
                  v-model="attending"
                  type="radio"
                  value="si"
                  class="form-check-input"
                  required
                >
                <label
                  class="form-check-label"
                  for="si"
                >Sí</label>
              </div>
              <div class="form-check">
                <input
                  id="no"
                  v-model="attending"
                  type="radio"
                  value="no"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="no"
                >No</label>
              </div>
            </div>
          </div>
          <div
            class="form-group mb-3"
          >
            <label
              for="guests"
              class="form-label"
            >¿Cuántos asisten?</label>
            <select
              id="guests"
              v-model="guests"
              class="form-select"
              :class="{ 'loading': loading }"
              :disabled="attending === 'no'"
            >
              <option
                value=""
                disabled
                selected
              >
                Selecciona una opción
              </option>
              <option
                v-for="n in guestsOptions"
                :key="n"
                :value="n"
              >
                {{ n }}
              </option>
              <option
                v-if="attending === 'no'"
                value="0"
              >
                0
              </option> <!-- Esta opción solo aparecerá si no asiste -->
            </select>
          </div>
  
          <button
            type="submit"
            class="btn btn-primary w-100"
          >
            Confirmar
          </button>
          <div class="mt-3">
            <div
              v-if="successMessage.length"
              class="alert alert-success text-center"
              :class="{ 'alert-danger': successMessage.includes('¡Intenta de nuevo!') }"
            >
              <div
                v-for="(line, index) in successMessage"
                :key="index"
              >
                {{ line }}<br v-if="index < successMessage.length - 1">
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted ,watch} from 'vue';
import axios from 'axios';
import baseUrl from '../api';  // Asegúrate de importar la URL base

export default {
  name: 'ConfirmacionAsistencia',
  props: {
    idInvitado: {
      type: Number,
      required: true,
      validator(value) {
        return !isNaN(value) && value > 0;
      }
    },
    nombre: {
      type: String,
      default: '',
    },
    idFestejado: {
      type: Number,
      default: null,
    }
  },
  setup(props) {
    const name = ref('');
    const phone = ref('');
    const invitedBy = ref('');
    const attending = ref('');
    const guests = ref('1');  // Valor por defecto
    const guestsOptions = ref([1, 2]);  // Opciones por defecto
    const successMessage = ref('');  // Definir successMessage
    const loading = ref(true); // Estado de carga
    const isXv = ref(props.nombre === 'XV' || props.nombre === 'xv' || props.nombre === 'mia' || props.nombre === 'MIA' );

    const fetchGuestLimit = async () => {
      try {
        const paqueteUrl = `${baseUrl}/${props.nombre ? `${props.nombre}/` : ''}${props.idFestejado ? `${props.idFestejado}/` : ''}${props.idInvitado ? `${props.idInvitado}` : ''}`;

        const response = await axios.get(paqueteUrl); // Aquí realizas la solicitud

        const data = response.data; // Accede a los datos directamente
        const numPases = data.num_pase; // Verifica que el nombre del campo sea correcto

        if (numPases === null || numPases === 0) {
          guestsOptions.value = [1, 2];
        } else {
          guestsOptions.value = Array.from({ length: numPases }, (_, i) => i + 1);
        }
      } catch (error) {
        console.error('Error al obtener el número de pases:', error);
      } finally {
        loading.value = false; // Cambia el estado de carga
      }
    };

    watch(attending, (newValue) => {
    if (newValue === 'no') {
      guests.value = '0'; // Cambia automáticamente a "0" si no asistirá
    } else if (newValue === 'si' && guests.value === '0') {
      guests.value = '1'; // Restaura el valor por defecto si decide asistir
    }
  });
    onMounted(() => {
      fetchGuestLimit();
    });

    const handleSubmit = async () => {
  if (!name.value || !phone.value || 
      (!invitedBy.value && !isXv.value) || 
      !attending.value || !guests.value) {
    successMessage.value = ['¡Faltan datos, completa el formulario!'];
    return;
  }
      const formData = {
        nombre_asistencia: name.value,
        telefono_invitado: phone.value,
        invitedby: isXv.value ? 'novia' : invitedBy.value,
        asistira: attending.value,
        id_festejada: props.idFestejado,  // Agregar el parámetro aquí
        id_invitado: props.idInvitado,  // Agregar el parámetro aquí
        num_pases: attending.value === 'no' ? 0 : guests.value, // Set to 0 if not attending

      };

      try {
        const response = await fetch(`${baseUrl}/asistencia`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error('Error al enviar el formulario.');
        }

        successMessage.value = [
          '¡Confirmación exitosa!',
          'Es muy importante que cualquier cancelación o cambio notificarla o volver a hacer tu registro.'
        ];        
        name.value = '';
        phone.value = '';
        invitedBy.value = '';
        attending.value = '';
        guests.value = '1';
      } catch (error) {
        successMessage.value = [
          '¡Intenta de nuevo!'
        ];      
      }
    };

    return {
      name,
      phone,
      invitedBy,
      attending,
      guests,
      guestsOptions,
      successMessage, // Asegúrate de devolver el mensaje
      handleSubmit,
      isXv,  // Asegúrate de devolver isXv

    };
  },
};
</script>

<style scoped>
.container {
  background-color: rgba(189, 181, 189, 0.484);
  background-size: contain; /* Cambia a contain para evitar distorsiones */
  background-position: center;

}

.overlay {
  max-width: 500px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.696); /* Ajusta la opacidad */
  backdrop-filter: blur(8px); /* Efecto de desenfoque */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
}
@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}
.custom-title {
  font-size: 1.7rem;
  text-align: center;
  color:rgb(171, 136, 180);
  font-family: 'FeelingPassionate';
  margin-bottom: 20px; /* Espaciado inferior */
}
.textninos {
  font-size: 22px;
  text-align: center;
  color: #373535; /* Color de texto */
  font-family: 'Roboto', cursive;
  font-weight: 600;
  margin-top: -10px; /* Espaciado inferior */
}


.btn {
  background-color: #bd62b76d; 
  border-color: #cfc6c3; /* Color del borde del botón */
  font-family: 'FeelingPassionate';
  opacity: 1;
  font-size:20px;
  line-height: 1.9; /* Ajusta la separación entre líneas */
  white-space: normal; /* Permite que el texto se ajuste a múltiples líneas */
  word-break: break-word; /* Asegura que el texto largo se ajuste en caso de ser necesario */ 
}

input,
textarea,
select {
  background-color: rgba(255, 255, 255, 0.6); /* Fondo blanco con transparencia */
  color: #333333; /* Color del texto más oscuro */
  border: 1px solid rgba(0, 0, 0, 0.466); /* Borde sutil */
  padding: 10px;
  font-weight: 600px;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;

  transition: background-color 0.3s ease, border-color 0.3s ease; /* Transición suave */
}

input:focus,
textarea:focus,
select:focus {
  background-color: rgba(255, 255, 255, 0.8); /* Reduce la transparencia al hacer foco */
  border-color: rgba(0, 0, 0, 0.5); /* Hace el borde más notorio */
  color: #333333; /* Mantiene el mismo color del texto en foco */
}

input:not(:focus),
textarea:not(:focus),
select:not(:focus) {
  color: #333333; /* Asegura que el color del texto siga siendo oscuro al salir del foco */
}

.form-check-input {
  background-color: white;
  background-color: #bd62b76d; 
  appearance: auto; /* Permite que el radio button mantenga su estilo por defecto */
  background-color: #bd62b76d; 
}

</style>
