<template>
  <div class="full-width-image">
    <!-- Imagen de fondo -->
    <img
      src="../assets/1.jpg"
      alt="Descripción de la imagen"
    >
    
    <!-- SVG decorativo posicionado en la parte inferior -->
    <img
      src="@/assets/curvas02.svg"
      alt="Decoración SVG"
      class="decorative-svg"
    >
  </div>
</template>

<script>
export default {
  name: 'FullWidthImage',
};
</script>

<style scoped>
/* Elimina el margen y el padding del body para evitar barras de desplazamiento */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evita que aparezca la barra de desplazamiento horizontal */
}

/* Contenedor de la imagen */
.full-width-image {
  width: 100%;
  position: relative; /* Es necesario para posicionar el SVG sobre la imagen */
  overflow: hidden; /* Oculta cualquier contenido que exceda el contenedor */
}

.full-width-image img {
  width: 100%;
  height: auto; /* Mantiene la relación de aspecto de la imagen */
  display: block; /* Elimina el espacio debajo de la imagen */
}

/* Estilo para el SVG decorativo */
.decorative-svg {
  position: absolute; /* Posiciona el SVG respecto al contenedor */
  bottom: 0; /* Sitúa el SVG en la parte inferior del contenedor */
  left: 0; /* Alinea el SVG al borde izquierdo */
  width: 100%; /* Hace que el SVG tenga el mismo ancho que la imagen */
  height: auto; /* Mantiene la proporción del SVG */
  pointer-events: none; /* El SVG no interfiere con interacciones del usuario */
  z-index: 2; /* Asegura que esté encima de la imagen */
}
</style>
